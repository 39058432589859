<template>
  <div>
    <el-dialog
      :title="etitle"
      :visible.sync="dialogTableVisible"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-form
        ref="formRules"
        :model="formData"
        :rules="formRules"
        label-width="80px"
      >
        <el-form-item label="选择职位" prop="DutyNo">
          <el-select v-model="formData.DutyNo" placeholder="请选择职位">
            <template v-for="(item, index) in selectData">
              <el-option
                v-if="index > 0"
                :key="index"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="级别名称" prop="LevelName">
          <el-input
            v-model="formData.LevelName"
            placeholder="请输入级别名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button
          ref="submitButton"
          type="primary"
          @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import employee from "@/api/employee.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "添加",
    },

    dialogTableVisible: {
      type: Boolean,
      default: false,
    },

    callFatherFunction: {
      type: Function,
    },

    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },

    selectData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formRules: {
        DutyNo: [
          { required: true, message: "请输入编号", trigger: "blur" },
          { min: 4, max: 8, message: "长度在 4 到 8 个字符", trigger: "blur" },
        ],

        LevelName: [
          { required: true, message: "请输入级别名称", trigger: "blur" },
          // { min: 4, max: 8, message: '长度在 4 到 8 个字符', trigger: 'blur' }
        ],

        LevelState: [
          { required: true, message: "请选择级别状态", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //
    onCloseDialog(formName) {
      this.callFatherFunction();
      this.$refs[formName].resetFields();
      this.$emit("closeDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let submitData = {
              DutyNo: this.formData.DutyNo,
              LevelName: this.formData.LevelName,
              LevelState: this.formData.LevelState,
            };
            this.$refs.submitButton.disabled = await true;
            // 如果有 LevelGuid 就是修改
            if (await this.formData.LevelGuid) {
              submitData.LevelGuid = this.formData.LevelGuid;
              let { data, errcode, errmsg } = await employee.updateLevel(
                submitData
              );
              if (errcode == (await 0)) {
                await this.onCloseDialog("formRules");
                await this.$message({
                  message: "修改成功!",
                  type: "success",
                });
              } else {
                await this.$message.error(errmsg);
              }
            } else {
              let { data, errcode, errmsg } = await employee.addLevel(
                submitData
              );
              if (errcode == (await 0)) {
                await this.onCloseDialog("formRules");
                await this.$message({
                  message: "添加成功!",
                  type: "success",
                });
              } else {
                await this.$message.error(errmsg);
              }
            }
            this.$refs.submitButton.disabled = await false;
          } catch (e) {
            console.log(e);
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>