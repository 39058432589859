<template>
  <div>
    <el-container>
      <!-- el-aside -->
      <el-aside width="10%">
        <el-select
          @change="onChangeSelect"
          v-model="selectStateValue"
          placeholder="请选择状态类型"
        >
          <el-option
            v-for="item in stateData"
            :key="item.id"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div>
          <div
            class="position"
            v-for="(item, index) in dutyList"
            :key="index"
            :class="indexAction == index ? 'action' : ''"
            @click="onAcion(index)"
          >
            {{ item.DutyName }}
          </div>
        </div> </el-aside
      ><!-- end el-aside -->
      <!-- el-main -->
      <el-main>
        <div class="public-margin-bottom flex-right">
          <el-button
            @click="addJobs"
            type="primary"
            icon="el-icon-plus"
            size="mini"
            >新增职位级别</el-button
          >
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          height="calc(100vh - 148px)"
          stripe
        >
          <el-table-column prop="LevelNo" label="级别编号"> </el-table-column>
          <el-table-column prop="DutyName" label="所属职位"> </el-table-column>
          <el-table-column prop="LevelName" label="级别名称"> </el-table-column>
          <el-table-column label="是属总部">
            <template slot-scope="scope">
              <div>{{ scope.row.HeadMark | whether }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="LevelName" label="状态">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.LevelState == 0"
                type="success"
                size="small"
                >正常</el-tag
              >
              <el-tag v-else type="danger" size="small">已删除</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div
                v-if="scope.row.LevelState == 1 || scope.row.LevelState == 3"
              >
                <el-button
                  size="mini"
                  type="success"
                  @click="onRestoreJobs(scope.row)"
                  >恢复</el-button
                >
              </div>
              <div v-else>
                <el-button
                  size="mini"
                  type="primary"
                  @click="onEditJobs(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="onDeleteJobs(scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table> </el-main
      ><!-- end el-main -->
    </el-container>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="showDialog"
      :callFatherFunction="callFatherFunction"
      :selectData="dutyList"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>
  </div>
</template>

<script>
import employee from "../../../api/employee.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  components: { EditDialog },
  data() {
    return {
      stateData: [
        { id: 2, name: "职位级别状态 - 正常", value: 0 },
        { id: 3, name: "职位级别状态 - 删除", value: 1 },
      ],
      selectStateValue: 0,
      indexAction: 0,
      initData: [],
      tableData: [],
      dutyList: [],
      formData: {},
      etitle: "",
      showDialog: false,
    };
  },

  mounted() {
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    async initPageData() {
      try {
        let { data } = await employee.getLevelMaintList();
        let { levelList, dutyList } = data;
        this.dutyList = [
          {
            DutyName: "全部",
            DutyNo: "",
            Sort: 0,
            StoresGuid: "",
            UseExpe: 0,
            ViceSymbol: "",
          },
          ...dutyList,
        ];
        this.initData = levelList;
        this.screenData();
      } catch (e) {
        console.error(e);
      }
    },

    screenData() {
      let dutyNo = this.dutyList[this.indexAction].DutyNo;

      let temp = [];
      this.initData.forEach((item, index) => {
        if (item.DutyNo == dutyNo || dutyNo == "") {
          if (item.LevelState == 0 && this.selectStateValue == 0) {
            temp.push(item);
          }

          if (
            (item.LevelState == 1 || item.LevelState == 3) &&
            this.selectStateValue == 1
          ) {
            temp.push(item);
          }
        }
      });
      this.tableData = temp;
    },

    onChangeSelect() {
      this.screenData();
    },

    onAcion(index) {
      this.indexAction = index;
      this.screenData();
    },

    addJobs() {
      this.etitle = "添加职位";
      this.formData = {
        DutyNo: "",
        LevelName: "",
        LevelState: 0,
      };
      this.showDialog = true;
    },

    onEditJobs(event) {
      this.etitle = "编辑职位";
      this.formData = event;
      this.showDialog = true;
    },

    async onDeleteJobs(event) {
      this.$confirm(`是否删除该‘${event.LevelName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await employee.deleteLevel({
              level_guid: event.LevelGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    onRestoreJobs(event) {
      this.$confirm(`是否恢复该‘${event.LevelName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await employee.restoreLevel({
              level_guid: event.LevelGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "恢复成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    callFatherFunction() {
      this.showDialog = false;
    },

    onCloseDialog() {
      this.initPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  .el-aside {
    height: calc(100vh - 100px);
    border-right: 1px solid #d3dce6;
    .jobs {
      height: 40px;
      line-height: 40px;
      padding-left: 15px;
      color: #606266;
      margin-bottom: 10px;
    }
    .position {
      height: 36px;
      line-height: 36px;
      padding-left: 15px;
      cursor: pointer;
    }
    .action {
      background-color: #f5f7fa;
    }
  }
  .el-main {
    position: relative;
    padding: 0 0 0 20px;
  }
}
</style>
